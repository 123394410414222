@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "relative-book";
  src: url("./fonts/relative-book.woff2") format("woff2"),
    url("./fonts/relative-book.woff") format("woff"),
    url("./fonts/relative-book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "relative-medium";
  src: url("./fonts/relative-medium.woff2") format("woff2"),
    url("./fonts/relative-medium.woff") format("woff"),
    url("./fonts/relative-medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.font-medium {
  font-family: relative-medium, sans-serif !important;
}

html,
body,
#root {
  font-family: relative-book, sans-serif !important;
  height: 100%;
  overflow: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 768px) {
  body {
    background-image: url("./images/DesktopBackground.png");
    background-position: center;
    background-size: cover;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
